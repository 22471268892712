import React, { useState } from 'react'
import Card from './Card'
import '../styles/pagination.scss'

const Pagination = ({ isBooks, data, pageLimit, dataLimit }) => {
    const [pages] = useState(Math.ceil(data.length / dataLimit))
    const [currentPage, setCurrentPage] = useState(1)
    const [lastGroupPage, setLastGroupPage] = useState(1)
    const [lastPage, setLastPage] = useState(pages)

    function goToNextPage() {
        setCurrentPage((page) => page + 1)
    }

    function goToPreviousPage() {
        setCurrentPage((page) => page - 1)
    }

    function changePage(event) {
        const pageNumber = Number(event.target.textContent)
        setCurrentPage(pageNumber)
    }

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit
        const endIndex = startIndex + dataLimit
        return data.slice(startIndex, endIndex)
    }

    const getPaginationGroup = () => {
        const pagesNeeded = Math.ceil(data.length / dataLimit)
        console.log('pages needed: ', pagesNeeded)

        if (pagesNeeded < pageLimit) {
            pageLimit = pagesNeeded
        }
        console.log('page limit', pageLimit)
        console.log('current page', currentPage)
        //let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit
        let start
        if (pagesNeeded >= (currentPage + pageLimit)) {
            start = currentPage - 1
        } else {
            start = pagesNeeded - pageLimit
        }
        console.log('start', start)
        const lastPageInThisGroup = start + pageLimit
        if (lastGroupPage != lastPageInThisGroup) {
            setLastGroupPage(lastPageInThisGroup)
        }
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1)
    }

    return (
        <div>
            {/* show the posts, 10 posts at a time */}
            <div className="card-grid">
                {getPaginatedData().map((d, idx) => (
                    <Card key={idx} data={d.node ? d.node : d} isBooks={isBooks} />
                ))}
            </div>
            {getPaginationGroup().length > 1 &&
                <div className="pagination">
                    {/* previous button */}
                    <button
                        onClick={goToPreviousPage}
                        disabled={currentPage === 1 ? true : false}
                        className={`pagination__prev ${currentPage === 1 ? 'pagination__button--disabled' : ''}`}
                    >
                        &lt; prev
                    </button>
                    {/* show page numbers */}
                    {getPaginationGroup().map((item, index) => (
                        <button
                            key={index}
                            onClick={changePage}
                            className={`pagination__item ${currentPage === item ? 'pagination__item--active' : ''}`}
                        >
                            <span>{item}</span>
                        </button>
                    ))}
                    {lastGroupPage !== pages &&
                        <>
                            {/* ellipsis with last page */}
                            <span className="pagination__ellipsis">...</span>
                            <button
                                onClick={changePage}
                                className={`pagination__item ${currentPage === lastPage ? 'pagination__item--active' : ''}`}
                            >
                                <span>{lastPage}</span>
                            </button>
                        </>
                    }
                    {/* next button */}
                    <button
                        onClick={goToNextPage}
                        disabled={currentPage === pages ? true : false}
                        className={`pagination__next ${currentPage === pages ? 'pagination__button--disabled' : ''}`}
                    >
                    next >
                    </button>
                </div>
            }
        </div>

    )
}

export default Pagination