import React, {useEffect, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSearch} from "@fortawesome/free-solid-svg-icons"
import Pagination from './Pagination'
import '../styles/facets.scss'
import '../styles/card-grid.scss'

const CardGridSearch = (props) => {
    const originalItems = props.items

    if (props.isBooks) {
        originalItems.sort((a,b)=> (a.title > b.title ? 1 : -1))
    }

    const [items, setItems] = useState(originalItems)


    useEffect(() => {
        if (!props.isBooks) {
            if (props.termType !== 'Scripture') {
                scripturePopulate(items)
            } else {
                chapterPopulate(items)
            }
            if (props.termType !== 'Resource_type')
                typePopulate(items)
            if (props.termType !== 'Topic') {
                topicPopulate(items)
            }
        }
    }, [])

    function typePopulate(items) {
        const typeDropdown = document.getElementById('typeSelect')
        const typeValue = typeDropdown.value
        const typeOptions = document.querySelectorAll('#typeSelect option')
        let options = []

        items.forEach(function (item, index) {
            if (item.resourceTypes)  {
                item.resourceTypes.nodes.forEach(function (node, index) {
                    options.push(node)
                })
            }
        })
        const newOptionTest = new Set()

        const newOptions = options.reduce((newArr, option) => {
            if (!newOptionTest.has(option.name)) {
                newOptionTest.add(option.name, option)
                newArr.push(option)
            }
            return newArr
        }, [])
        typeOptions.forEach(o => o.remove())
        let newOption = document.createElement('option')
        newOption.textContent = "All Types"
        newOption.value = "all"
        typeDropdown.appendChild(newOption)
        newOptions.forEach(function (option, index) {
            let newOption = document.createElement('option')
            newOption.textContent = option.name
            newOption.value = option.slug
            typeDropdown.appendChild(newOption)
        })
        if (typeValue !== 'all') {
            typeDropdown.value = typeValue
        }
    }

    function topicPopulate(items) {
        const topicDropdown = document.getElementById('topicSelect')
        const topicValue = topicDropdown.value
        const topicOptions = document.querySelectorAll('#topicSelect option')
        let options = []

        items.forEach(function (item, index) {
            item.topics?.nodes.forEach(function (node, index) {
                options.push(node)
            })
        })
        const newOptionTest = new Set()

        const newOptions = options.reduce((newArr, option) => {
            if (!newOptionTest.has(option.name)) {
                newOptionTest.add(option.name, option)
                newArr.push(option)
            }
            return newArr
        }, [])
        topicOptions.forEach(o => o.remove())
        let newOption = document.createElement('option')
        newOption.textContent = "All Topics"
        newOption.value = "all"
        topicDropdown.appendChild(newOption)
        newOptions.forEach(function (option, index) {
            let newOption = document.createElement('option')
            newOption.textContent = option.name
            newOption.value = option.slug
            topicDropdown.appendChild(newOption)
        })
        if (topicValue !== 'all') {
            topicDropdown.value = topicValue
        }
    }

    function scripturePopulate(items) {
        const scriptureDropdown = document.getElementById('scriptureSelect')
        const scriptureValue = scriptureDropdown.value
        const scriptureOptions = document.querySelectorAll('#scriptureSelect option')
        let options = []

        items.forEach(function (item, index) {
            item.scriptures?.nodes.forEach(function (node, index) {
                options.push(node)
            })
        })

        const newOptionTest = new Set()

        const newOptions = options.reduce((newArr, option) => {
            if (!newOptionTest.has(option.name)) {
                newOptionTest.add(option.name, option)
                newArr.push(option)
            }
            return newArr
        }, [])

        scriptureOptions.forEach(o => o.remove())
        let newOption = document.createElement('option')
        newOption.textContent = "Any Scripture"
        newOption.value = "all"
        scriptureDropdown.appendChild(newOption)
        newOptions.forEach(function (option, index) {
            let newOption = document.createElement('option')
            newOption.textContent = option.name
            newOption.value = option.slug
            scriptureDropdown.appendChild(newOption)
        })

        if (scriptureValue !== 'all') {
            scriptureDropdown.value = scriptureValue
        }

    }

    function chapterPopulate(items) {
        const scriptureChapterContainer = document.getElementById('chapter-options')
        const scriptureChapterOptions = document.querySelectorAll('#chapter-options li')
        let chapterOptions = []


        items.forEach(function (item, index) {
            if (item.scriptureField && item.scriptureField.scriptureChapterReference > 0) {
                chapterOptions.push(item.scriptureField.scriptureChapterReference)
            }
        })

        let newChapterOptions = [...new Set(chapterOptions)];
        newChapterOptions.sort(function(a, b) {
            return a - b;
        });

        scriptureChapterOptions.forEach(o => o.remove())

        let newOptionWrapper = document.createElement('li')
        let newOption = document.createElement('a')
        newOption.textContent = "All"
        newOption.href = "#"
        newOptionWrapper.appendChild(newOption)
        scriptureChapterContainer.appendChild(newOptionWrapper)


        newChapterOptions.forEach(function(option, index) {
            let newOptionWrapper = document.createElement('li')
            let newOption = document.createElement('a')
            newOption.textContent = option
            newOption.href = "/" + option
            newOptionWrapper.appendChild(newOption)
            scriptureChapterContainer.appendChild(newOptionWrapper)
        })
    }

    function handleChapterChange(event) {
        event.preventDefault()
        const value = String(event.target.textContent)
        let typeExists
        let topicExists
        let typeValue
        let topicValue


        if (document.getElementById('typeSelect')) {
            typeExists = true
            typeValue = document.getElementById('typeSelect').value
        }

        if (document.getElementById('topicSelect')) {
            topicExists = true
            topicValue = document.getElementById('topicSelect').value
        }

        if (value !== 'All') {
            let filteredItems = originalItems.filter((item) => item.scriptureField?.scriptureChapterReference === value)
            if (typeExists) {
                if (typeValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.resourceTypes.nodes.find(node => node.slug === typeValue)
                    })
                }
            }
            if (topicExists) {
                if (topicValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.topics.nodes.find(node => node.slug === topicValue)
                    })
                }
            }
            chapterPopulate(filteredItems)
            if (typeExists) {
                typePopulate(filteredItems)
            }
            if (topicExists) {
                topicPopulate(filteredItems)
            }

            console.log('filtered items: ', filteredItems)
            setItems(filteredItems)
        } else {
            let filteredItems = originalItems
            if (typeExists) {
                if (typeValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.resourceTypes.nodes.find(node => node.slug === typeValue)
                    })
                }
            }
            if (topicExists) {
                if (topicValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.topics.nodes.find(node => node.slug === topicValue)
                    })
                }
            }
            chapterPopulate(filteredItems)
            if (typeExists) {
                typePopulate(filteredItems)
            }
            if (topicExists) {
                topicPopulate(filteredItems)
            }
            setItems(filteredItems)
        }

    }

    function handleScriptureChange(event) {
        const value = event.target.value
        let typeExists
        let topicExists
        let typeValue
        let topicValue

        if (document.getElementById('typeSelect')) {
            typeExists = true
            typeValue = document.getElementById('typeSelect').value
        }

        if (document.getElementById('topicSelect')) {
            topicExists = true
            topicValue = document.getElementById('topicSelect').value
        }

        if (value !== 'all') {
            let filteredItems = originalItems.filter(function (item, index) {
                return item.scriptures.nodes.find(node => node.slug === value)
            })
            if (typeExists) {
                if (typeValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.resourceTypes.nodes.find(node => node.slug === typeValue)
                    })
                }
            }
            if (topicExists) {
                if (topicValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.topics.nodes.find(node => node.slug === topicValue)
                    })
                }
            }
            scripturePopulate(filteredItems)
            if (typeExists) {
                typePopulate(filteredItems)
            }
            if (topicExists) {
                topicPopulate(filteredItems)
            }
            setItems(filteredItems)
        } else {
            let filteredItems = originalItems
            if (typeExists) {
                if (typeValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.resourceTypes.nodes.find(node => node.slug === typeValue)
                    })
                }
            }
            if (topicExists) {
                if (topicValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.topics.nodes.find(node => node.slug === topicValue)
                    })
                }
            }
            scripturePopulate(filteredItems)
            if (typeExists) {
                typePopulate(filteredItems)
            }
            if (topicExists) {
                topicPopulate(filteredItems)
            }
            setItems(filteredItems)
        }
    }

    function handleTypeChange(event) {
        const value = event.target.value
        let scriptureExists
        let topicExists
        let scriptureValue
        let topicValue

        if (document.getElementById('scriptureSelect')) {
            scriptureExists = true
            scriptureValue = document.getElementById('scriptureSelect').value
        }

        if (document.getElementById('topicSelect')) {
            topicExists = true
            topicValue = document.getElementById('topicSelect').value
        }

        if (value !== 'all') {
            let filteredItems = originalItems.filter(function (item, index) {
                return item.resourceTypes.nodes.find(node => node.slug === value)
            })
            if (scriptureExists) {
                if (scriptureValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.scriptures.nodes.find(node => node.slug === scriptureValue)
                    })
                }
            }
            if (topicExists) {
                if (topicValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.topics.nodes.find(node => node.slug === topicValue)
                    })
                }
            }
            if (scriptureExists) {
                scripturePopulate(filteredItems)
            }
            typePopulate(filteredItems)
            if (topicExists) {
                topicPopulate(filteredItems)
            }
            setItems(filteredItems)
        } else {
            let filteredItems = originalItems
            if (scriptureExists) {
                if (scriptureValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.scriptures.nodes.find(node => node.slug === scriptureValue)
                    })
                }
            }
            if (topicExists) {
                if (topicValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.topics.nodes.find(node => node.slug === topicValue)
                    })
                }
            }
            if (scriptureExists) {
                scripturePopulate(filteredItems)
            }
            typePopulate(filteredItems)
            if (topicExists) {
                topicPopulate(filteredItems)
            }
            setItems(filteredItems)
        }
    }

    function handleTopicChange(event) {
        const value = event.target.value
        let scriptureExists
        let typeExists
        let scriptureValue
        let typeValue

        if (document.getElementById('scriptureSelect')) {
            scriptureExists = true
            scriptureValue = document.getElementById('scriptureSelect').value
        }

        if (document.getElementById('typeSelect')) {
            typeExists = true
            typeValue = document.getElementById('typeSelect').value
        }

        if (value !== 'all') {
            let filteredItems = originalItems.filter(function (item, index) {
                return item.topics.nodes.find(node => node.slug === value)
            })
            if (scriptureValue !== 'all') {
                filteredItems = filteredItems.filter(function (item, index) {
                    return item.scriptures.nodes.find(node => node.slug === scriptureValue)
                })
            }
            if (typeExists) {
                if (typeValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.resourceTypes.nodes.find(node => node.slug === typeValue)
                    })
                }
            }
            if (scriptureExists) {
                scripturePopulate(filteredItems)
            }
            if (typeExists) {
                typePopulate(filteredItems)
            }
            topicPopulate(filteredItems)
            setItems(filteredItems)
        } else {
            let filteredItems = originalItems
            if (scriptureValue !== 'all') {
                filteredItems = originalItems.filter(function (item, index) {
                    return item.scriptures.nodes.find(node => node.slug === scriptureValue)
                })
            }
            if (typeExists) {
                if (typeValue !== 'all') {
                    filteredItems = filteredItems.filter(function (item, index) {
                        return item.resourceTypes.nodes.find(node => node.slug === typeValue)
                    })
                }
            }
            if (scriptureExists) {
                scripturePopulate(filteredItems)
            }
            if (typeExists) {
                typePopulate(filteredItems)
            }
            topicPopulate(filteredItems)
            setItems(filteredItems)
        }
    }

    if (props.isBooks) {
        return (
            <>
                <div className="facets container">
                    {props.hideSearch !== true &&
                    <form action="/search" className="facets__input-group facets__input-group--search">
                        <label htmlFor="searchFacet">Search the resource library:</label>
                        <div className="facets__input-group__input-wrapper--text">
                            <input name="q" type="text" id="searchFacet" placeholder="Type to Search"/>
                            <span><FontAwesomeIcon icon={faSearch}/></span>
                        </div>
                    </form>
                    }
                </div>
                <div className="container">
                    <Pagination
                        data={items}
                        pageLimit={5}
                        dataLimit={16}
                        isBooks={props.isBooks}
                    />
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="facets container">
                    {props.termType === 'Scripture' &&
                    <div className="facets__chapter-group">
                        <label>Filter by Chapter:</label>
                        <ul id="chapter-options" onClick={handleChapterChange}>

                        </ul>
                    </div>
                    }
                    {props.termType !== 'Resource_type' &&
                    <div className="facets__input-group">
                        <label htmlFor="typeSelect">Filter Results by Type:</label>
                        <div className="facets__input-group__input-wrapper--select">
                            <select name="resource-type" id="typeSelect" onChange={handleTypeChange}>
                                <option value="all">All Types</option>
                                <option value="devotionals">Devotionals</option>
                                <option value="bible-studies">Bible Studies</option>
                                <option value="articles">Articles</option>
                            </select>
                        </div>
                    </div>
                    }
                    {props.termType !== 'Scripture' &&
                    <div className="facets__input-group">
                        <label htmlFor="scriptureSelect">Filter Results by Scripture:</label>
                        <div className="facets__input-group__input-wrapper--select">
                            <select name="resource-type" id="scriptureSelect" onChange={handleScriptureChange}>
                                <option value="all">All Scriptures</option>
                                <option value="1-corinthians">1 Corinthians</option>
                                <option value="genesis">Genesis</option>
                            </select>
                        </div>
                    </div>
                    }
                    {props.termType !== 'Topic' &&
                    <div className="facets__input-group">
                        <label htmlFor="topicSelect">Filter Results by Topic:</label>
                        <div className="facets__input-group__input-wrapper--select">
                            <select name="resource-type" id="topicSelect" onChange={handleTopicChange}>
                                <option value="all">All Topics</option>
                                <option value="1-corinthians">1 Corinthians</option>
                                <option value="genesis">Genesis</option>
                            </select>
                        </div>
                    </div>
                    }
                    {props.hideSearch !== true &&
                    <form action="/search" className="facets__input-group facets__input-group--search">
                        <label htmlFor="searchFacet">Search the resource library:</label>
                        <div className="facets__input-group__input-wrapper--text">
                            <input name="q" type="text" id="searchFacet" placeholder="Type to Search"/>
                            <span><FontAwesomeIcon icon={faSearch}/></span>
                        </div>
                    </form>
                    }
                </div>
                <div className="container">
                    <Pagination
                        data={items}
                        pageLimit={5}
                        dataLimit={16}
                        isBooks={props.isBooks}
                    />
                </div>
            </>
        )
    }
}

export default CardGridSearch