import algoliasearch from 'algoliasearch'
import React, { useState, useEffect } from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import CardGridSearch from '../components/CardGridSearch'
import ImageHeader from '../components/ImageHeader'
import Layout from '../components/Layout'

const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY)
const index = client.initIndex('bible_to_life')

const isBrowser = typeof window !== "undefined"
let query
if (isBrowser) {
    const {search} = window.location
    query = new URLSearchParams(search).get('q')
}



const SearchPage = (props) => {
    const [retrievedHits, setHits ] = useState(null)
    if (query !== '' && retrievedHits === null) {
        index.search(query).then(({hits}) => {
            console.log('hits: ', hits)
            setHits(hits)
        })
    }
    return (
        <Layout>
            { retrievedHits &&
                <section className="search-results">
                    <ImageHeader
                        title={`Search results for "${query}"`} />
                    <Breadcrumbs title="Search Results" />
                    <CardGridSearch  hideSearch={true} items={retrievedHits} />
                </section>
            }
        </Layout>
    )
}

export default SearchPage